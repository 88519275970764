<template>
  <div class="mb-4">
      <CRow class="mb-0">
        <CCol lg="3">
          <CInput size="sm" v-model="name" placeholder="Vehicle Type"/>
        </CCol>
        <CCol lg="3">
        </CCol>
        <CCol lg="3">
        </CCol>
        <CCol lg="1">
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton size="sm" block color="info" @click="search"><i class="icon-magnifier"></i> Search</CButton>
        </CCol>
      </CRow>
      <CRow>
        <CCol lg="2">
        </CCol>
        <CCol lg="2">
        </CCol>
        <CCol lg="2">
        </CCol>
        <CCol lg="2">
        </CCol>
        <CCol lg="2">
        </CCol>
        <CCol lg="2" class="float-right">
          <CButton size="sm" block @click="clearSearch" color="info">Clear Search</CButton>
        </CCol>
      </CRow>
  </div>
</template>

<script>
    export default {
        name: "VehicletypeSearch",
        data() {
          return {
            name: ""
          }
        },
      mounted() {
      },
      methods: {
        search() {
          const data = {
            name: this.name
          }
          this.$emit("vehicletype-search-query", data)
        },
        clearSearch() {
          this.name = ""
          const data = {
            name: this.name
          }
          this.$emit("vehicletype-search-query", data)
        }
      }
    }
</script>

<style scoped>

</style>
